import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../componants/navbar";
import Faq from "../componants/faq";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";

import { servicesData } from "../data/data";
import { Helmet } from 'react-helmet';
export default function Services(){
    return(
        <>
        <Helmet>
            <title>How It Works - 9Jobs</title>
            <meta name="description" content="Discover how 9Jobs connects job seekers with employers, providing 24/7 support, personalized job matches, and a streamlined application process. Find tech and startup opportunities, apply with confidence, and minimize hiring bias." />
            <meta name="keywords" content="9Jobs, job search, job opportunities, tech jobs, startup jobs, job application, personalized job matches, diversity, hiring bias, job support" />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="How It Works - 9Jobs" />
            <meta property="og:description" content="Learn how 9Jobs makes your job search seamless and efficient with 24/7 support, tech and startup opportunities, and personalized job matches." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.9jobs.in/services" />
            <meta property="og:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="How It Works - 9Jobs" />
            <meta name="twitter:description" content="Discover how 9Jobs connects job seekers with employers and provides a smooth job search experience." />
            <meta name="twitter:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />


        </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">How it works?</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Services</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                <div className="row g-4">
                    {servicesData.map((item,index)=>{
                        let Icon = item.icon
                        return(
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2" key={index}>
                            <div className="position-relative features text-center p-4 rounded shadow bg-white">
                                <div className="feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center">
                                    <Icon className="fea icon-ex-md"/>
                                </div>
        
                                <div className="mt-4">
                                    <Link to="" className="title h5 text-dark">{item.title}</Link>
                                    <p className="text-muted mt-3 mb-0">{item.desc}</p>
                                    {/* <div className="mt-3">
                                        <Link to="" className="btn btn-link primary text-dark">Read More <i className="mdi mdi-arrow-right"></i></Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <Faq/>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}