import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg1 from '../assets/images/hero/bg.jpg'
import Navbar from "../componants/navbar";
import AboutTwo from "../componants/aboutTwo";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import moment from 'moment';
import { Helmet } from 'react-helmet';
import {FiClock,FiMapPin, FiBookmark} from "../assets/icons/vander"


export default function JobListTwo() {
    // State for jobs and filters
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("");
    const [location, setLocation] = useState("");
    const [title, setTitle] = useState("");
    const [jobType, setJobType] = useState([]);
    const [salaryRange, setSalaryRange] = useState("");
    const [page, setPage] = useState(1); // Pagination: Track the current page
    const [totalPages, setTotalPages] = useState(20); // Track the total number of pages
    const pageLimit = 10; // Maximum pages to display at a time
    // Fetch jobs from API with filters
    const fetchJobs = async () => {
        try {
            const response = await fetch('https://www.9jobs.in/job-fetcher/jobs/recentJobs?page='+page+'&limit='+pageLimit, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
             
                    category,
                    location,
                    jobType: jobType.join(","), // Join selected job types as comma-separated values
                    salaryRange,
                    jobTitle:title
                }),
            });
            const data = await response.json();
            setJobs(data.data);
            setLoading(false);
            setTotalPages(data.totalPages); // Set total pages from the response
        } catch (error) {
            console.error("Error fetching jobs:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, [page,title,category, location, jobType, salaryRange]); // Re-fetch jobs when filters change
 // Pagination: Calculate start and end pages for the visible pagination
 const startPage = Math.max(1, page - Math.floor(pageLimit / 2));
 const endPage = Math.min(totalPages, startPage + pageLimit - 1);
 const handlePageClick = (pageNum) => {
    setPage(pageNum);
};

 // Handle pagination
 const handlePreviousPage = () => {
    if (page > 1) {
        setPage(page - 1);
    }
};

const handleNextPage = () => {
    if (page < totalPages) {
        setPage(page + 1);
    }
};
    // Handle filter changes
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleCategoryChange = (e) => setCategory(e.target.value);
    const handleLocationChange = (e) => setLocation(e.target.value);
    const handleJobTypeChange = (e) => {
        const value = e.target.value;
        setJobType((prev) =>
            prev.includes(value) ? prev.filter((type) => type !== value) : [...prev, value]
        );
    };
    const handleSalaryRangeChange = (e) => setSalaryRange(e.target.value);

    return (
        <>
            <Helmet>
               <title>Job Vacancies | Discover Top Career Opportunities with 9 Jobs</title>
                <meta name="description" content="Explore thousands of job vacancies across various industries with 9 Jobs. Get personalized salary estimates, company insights, and reviews to make informed career decisions." />
                <meta name="keywords" content="job vacancies, career opportunities, job search, salary estimates, company reviews, 9 Jobs, employment, industry-specific jobs" />

                <meta property="og:title" content="Job Vacancies | Discover Top Career Opportunities with 9 Jobs" />
                <meta property="og:description" content="Find job opportunities in multiple industries with 9 Jobs. Access personalized salary insights and company reviews from over 30,000 organizations worldwide to support your career journey." />
                <meta property="og:image" content="https://9jobs.in/images/og-image-job-vacancies.jpg" />
                <meta property="og:url" content="https://9jobs.in/job-list-two" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Job Vacancies | Discover Top Career Opportunities with 9 Jobs" />
                <meta name="twitter:description" content="Explore job listings across industries, view salary estimates, and learn about company cultures with insights from 30,000+ organizations worldwide on 9 Jobs." />
                <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-job-vacancies.jpg" />

                <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-aboutus.jpg" />


            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Job Vacancies</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="card bg-white p-4 rounded shadow sticky-bar">
                                <div>
                                    <h6 className="mb-0">Search Job</h6>
                                    <input type="text" className="form-control rounded border mt-2" placeholder="Search..."  onChange={handleTitleChange} />
                                </div>

                                <div className="mt-4">
                                    <h6 className="mb-0">Categories</h6>
                                    <select className="form-select form-control border mt-2" onChange={handleCategoryChange}>
                                            <option value="">Select Category</option>
                                            <option value="16">IT</option>
                                            <option value="17">Finance</option>
                                            <option value="18">Healthcare</option>
                                            <option value="19">Engineering</option>
                                            <option value="20">Marketing</option>
                                            <option value="21">Education</option>
                                            <option value="22">Manufacturing</option>
                                            <option value="23">Real Estate</option>
                                            <option value="24">BPO</option>
                                    </select>

                                </div>

                                <div className="mt-4">
                                    <h6 className="mb-0">Location</h6>
                                    <select className="form-select form-control border mt-2" onChange={handleLocationChange}>
                                        <option value="">Select Location</option>
                                        <option value="1">Mumbai</option>
                                        <option value="2">Kolkata</option>
                                        <option value="7">Jaipur</option>
                                        <option value="8">Gandhinagar</option>
                                        <option value="11">Rajkot</option>
                                        <option value="12">Other</option>
                                        <option value="13">Bangalore</option>
                                        <option value="14">Vadodara</option>
                                        <option value="15">Delhi</option>
                                        <option value="16">Ahmedabad</option>
                                        <option value="17">Surat</option>
                                        <option value="19">Hyderabad</option>
                                        <option value="20">Noida</option>
                                        <option value="21">Gurgaon</option>
                                        <option value="22">Chandigarh</option>
                                        <option value="23">Lucknow</option>
                                        <option value="24">Varanasi</option>
                                        <option value="25">Amritsar</option>
                                        <option value="26">Bengaluru</option>
                                        <option value="27">Chennai</option>
                                        <option value="28">Coimbatore</option>
                                        <option value="29">Kochi</option>
                                        <option value="30">Mysuru</option>
                                        <option value="31">Pune</option>
                                        <option value="32">Goa</option>
                                        <option value="33">Guwahati</option>
                                        <option value="34">Bhubaneswar</option>
                                        <option value="35">Ranchi</option>
                                        <option value="36">Patna</option>
                                        <option value="37">Bhopal</option>
                                        <option value="38">Indore</option>
                                        <option value="39">Shillong</option>
                                        <option value="40">Imphal</option>
                                        <option value="41">Panaji</option>
                                        <option value="42">California</option>
                                        <option value="43">Texas</option>
                                        <option value="44">Florida</option>
                                        <option value="45">Virginia</option>
                                        <option value="46">Alaska</option>
                                        <option value="47">Idaho</option>
                                        <option value="48">Hawaii</option>
                                        <option value="49">Ohio</option>
                                        <option value="50">Arizona</option>
                                        <option value="51">Georgia (U.S. state)</option>
                                        <option value="52">Massachusetts</option>
                                        <option value="53">Pennsylvania</option>
                                        <option value="54">Colorado</option>
                                        <option value="55">New Jersey</option>
                                        <option value="56">Michigan</option>
                                        <option value="57">North Carolina</option>
                                        <option value="58">Illinois</option>
                                        <option value="59">Maryland</option>
                                        <option value="60">Indiana</option>
                                        <option value="61">Washington</option>
                                        <option value="62">New York State</option>
                                        <option value="63">Minnesota</option>
                                        <option value="64">Utah</option>
                                        <option value="65">Alabama</option>
                                        <option value="66">Oregon</option>
                                        <option value="67">Tennessee</option>
                                        <option value="68">Missouri</option>
                                        <option value="69">Montana</option>
                                        <option value="70">Maine</option>
                                        <option value="71">Connecticut</option>
                                        <option value="72">New Mexico</option>
                                        <option value="73">Louisiana</option>
                                        <option value="75">Kentucky</option>
                                        <option value="76">Kansas</option>
                                        <option value="77">Wisconsin</option>
                                        <option value="78">Wisconsin</option>
                                        <option value="79">New Hampshire</option>
                                        <option value="80">Nevada</option>
                                        <option value="81">Oklahoma</option>
                                        <option value="82">Iowa</option>
                                        <option value="83">South Carolina</option>
                                        <option value="84">Wyoming</option>
                                        <option value="85">Arkansas</option>
                                        <option value="86">Mississippi</option>
                                        <option value="87">North Dakota</option>
                                        <option value="88">Nebraska</option>
                                        <option value="89">West Virginia</option>
                                        <option value="90">Delaware</option>
                                        <option value="91">Vermont</option>
                                        <option value="92">Rhode Island</option>
                                        <option value="93">South Dakota</option>
                                        <option value="94">New South Wales</option>
                                        <option value="95">Victoria</option>
                                        <option value="96">Queensland</option>
                                        <option value="97">Western Australia</option>
                                        <option value="98">South Australia</option>
                                        <option value="99">Tasmania</option>
                                        <option value="100">Australian Capital Territory</option>
                                        <option value="101">Northern Territory</option>
                                        <option value="102">Alberta</option>
                                        <option value="103">British Columbia</option>
                                        <option value="104">Manitoba</option>
                                        <option value="105">New Brunswick</option>
                                        <option value="106">Newfoundland and Labrador</option>
                                        <option value="107">Northwest Territories</option>
                                        <option value="108">Nova Scotia</option>
                                        <option value="109">Nunavut</option>
                                        <option value="110">Ontario</option>
                                        <option value="111">Prince Edward Island</option>
                                        <option value="112">Quebec</option>
                                        <option value="113">Saskatchewan</option>
                                        <option value="114">Yukon</option>
                                        <option value="115">Auvergne-Rhône-Alpes</option>
                                        <option value="116">Bourgogne-Franche-Comté</option>
                                        <option value="117">Brittany</option>
                                        <option value="118">Centre-Val de Loire</option>
                                        <option value="119">Corsica</option>
                                        <option value="121">Grand Est</option>
                                        <option value="122">Hauts-de-France</option>
                                        <option value="123">Paris Region</option>
                                        <option value="124">Normandie</option>
                                        <option value="125">Nouvelle-Aquitaine</option>
                                        <option value="126">Occitanie</option>
                                        <option value="127">Pays de la Loire</option>
                                        <option value="128">Provence Alpes Côte d’Azur</option>
                                        <option value="129">North Rhine-Westphalia</option>
                                        <option value="130">Bavaria</option>
                                        <option value="131">Saxony</option>
                                        <option value="132">Hesse</option>
                                        <option value="133">Baden-Württemberg</option>
                                        <option value="134">Thuringia</option>
                                        <option value="135">Lower Saxony</option>
                                        <option value="136">Saarland</option>
                                        <option value="137">Schleswig-Holstein</option>
                                        <option value="138">Rhineland-Palatinate</option>
                                        <option value="139">Mecklenburg-Vorpommern</option>
                                        <option value="140">Saxony-Anhalt</option>
                                        <option value="142">Brandenburg</option>
                                        <option value="143">Bremen</option>
                                        <option value="144">Hamburg</option>
                                        <option value="145">Berlin</option>
                                        <option value="146">Abruzzo</option>
                                        <option value="147">Basilicata</option>
                                        <option value="148">Calabria</option>
                                        <option value="149">Campania</option>
                                        <option value="150">Emilia-Romagna</option>
                                        <option value="151">Friuli Venezia Giulia</option>
                                        <option value="152">Lazio</option>
                                        <option value="153">Liguria</option>
                                        <option value="154">Lombardy</option>
                                        <option value="155">Marche</option>
                                        <option value="156">Molise</option>
                                        <option value="157">Piemonte</option>
                                        <option value="158">Puglia</option>
                                        <option value="159">Sardinia</option>
                                        <option value="160">Sicily</option>
                                        <option value="161">Sicily</option>
                                        <option value="162">Trentino-Alto Adige/Südtirol</option>
                                        <option value="163">Tuscany</option>
                                        <option value="164">Umbria</option>
                                        <option value="165">Valle d'Aosta</option>
                                        <option value="166">Veneto</option>
                                        <option value="167">Auckland</option>
                                        <option value="168">Bay of Plenty</option>
                                        <option value="169">Canterbury</option>
                                        <option value="170">Gisborne</option>
                                        <option value="171">Hawke’s Bay</option>
                                        <option value="172">Manawatu-Wanganui</option>
                                        <option value="173">Marlborough</option>
                                        <option value="174">Nelson</option>
                                        <option value="175">Northland</option>
                                        <option value="176">Otago</option>
                                        <option value="177">Southland</option>
                                        <option value="178">Taranaki</option>
                                        <option value="179">Tasman</option>
                                        <option value="180">Waikato</option>
                                        <option value="181">Wellington</option>
                                        <option value="182">West Coast</option>
                                        <option value="183">Singapore</option>
                                        <option value="184">Andalusia</option>
                                        <option value="185">Aragon</option>
                                        <option value="186">Asturias</option>
                                        <option value="187">Balearic Islands</option>
                                        <option value="188">Basque country</option>
                                        <option value="189">Canary Islands</option>
                                        <option value="190">Cantabria</option>
                                        <option value="191">Castile and León</option>
                                        <option value="192">Castile-La Mancha</option>
                                        <option value="193">Catalonia</option>
                                        <option value="194">Extremadura</option>
                                        <option value="195">Galicia</option>
                                        <option value="196">La Rioja</option>
                                        <option value="197">Madrid</option>
                                        <option value="198">Murcia</option>
                                        <option value="199">Navarre</option>
                                        <option value="200">Valencia</option>
                                        <option value="201">England</option>
                                        <option value="202">Scotland</option>
                                        <option value="203">Wales</option>
                                        <option value="204">Northern Ireland</option>
                                        <option value="205">Andhra Pradesh</option>
                                        <option value="206">Arunachal Pradesh</option>
                                        <option value="207">Assam</option>
                                        <option value="208">Bihar</option>
                                        <option value="209">Chhattisgarh</option>
                                        <option value="210">Gujarat</option>
                                        <option value="211">Haryana</option>
                                        <option value="212">Himachal Pradesh</option>
                                        <option value="213">Jharkhand</option>
                                        <option value="214">Karnataka</option>
                                        <option value="215">Kerala</option>
                                        <option value="216">Madhya Pradesh</option>
                                        <option value="217">Maharashtra</option>
                                        <option value="218">Manipur</option>
                                        <option value="219">Meghalaya</option>
                                        <option value="220">Mizoram</option>
                                        <option value="221">Nagaland</option>
                                        <option value="222">Odisha</option>
                                        <option value="223">Punjab</option>
                                        <option value="224">Rajasthan</option>
                                        <option value="225">Sikkim</option>
                                        <option value="226">Tamil Nadu</option>
                                        <option value="227">Telangana</option>
                                        <option value="228">Tripura</option>
                                        <option value="229">Uttar Pradesh</option>
                                        <option value="230">Uttarakhand</option>
                                        <option value="231">West Bengal</option>
                                        <option value="232">Andaman and Nicobar Islands</option>
                                        <option value="233">Chandigarh</option>
                                        <option value="234">Dadra and Nagar Haveli and Daman and Diu</option>
                                        <option value="235">Lakshadweep</option>
                                        <option value="236">Puducherry</option>
                                        <option value="237">Jammu and Kashmir</option>
                                        <option value="238">Ladakh</option>
                                        <option value="239">Gurugram</option>
                                        <option value="240">Kurukshetra</option>
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <h6>Job Types</h6>
                                    <div className="d-flex flex-column">
                                        <label>
                                            <input type="checkbox" value="Freelance" onChange={handleJobTypeChange} /> Freelance
                                        </label>
                                        <label>
                                            <input type="checkbox" value="Full Time" onChange={handleJobTypeChange} /> Full Time
                                        </label>
                                        <label>
                                            <input type="checkbox" value="Part Time" onChange={handleJobTypeChange} /> Part Time
                                        </label>
                                        <label>
                                            <input type="checkbox" value="Contract" onChange={handleJobTypeChange} /> Contract
                                        </label>
                                        <label>
                                            <input type="checkbox" value="Internship" onChange={handleJobTypeChange} /> Internship
                                        </label>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <h6 className="mb-0">Salary</h6>
                                    <select className="form-select form-control border mt-2" onChange={handleSalaryRangeChange}>
                                        <option value="">Select Salary Range</option>
                                        <option value="10000-15000">10k - 15k</option>
                                        <option value="15000-25000">15k - 25k</option>
                                        <option value="25000-100000">More than 25k</option>
                                    </select>
                                </div>

                                <div className="mt-4">
                                    <button className="btn btn-primary w-100" onClick={fetchJobs}>Apply Filter</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-6 col-12">
                            {loading ? (
                                <p>Loading jobs...</p>
                            ) : (
                                <div className="row g-4">
                                    {jobs.map((item, index) => (
                                        <div className="col-12" key={index}>
                                            <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                                <div className="d-flex align-items-center w-250px">
                                                    <img src={"https://www.9jobs.in/employer/"+item.image} className="avatar avatar-small rounded shadow p-3 bg-white" alt="" />
                                                    <div className="ms-3">
                                                        <Link to={`/job-detail-one/${item.id}`} className="h5 title text-dark">{item.title}</Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                                    <span className="badge bg-soft-primary rounded-pill">{item.jobTime}</span>
                                                    <span className="text-muted d-flex align-items-center fw-medium mt-md-2">
                                                        <FiClock className="fea icon-sm me-1 align-middle" /> { moment(item.date * 1000).fromNow() }
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between d-md-block mt-2 mt-md-0 w-130px">
                                                    <span className="text-muted d-flex align-items-center">
                                                        <FiMapPin className="fea icon-sm me-1 align-middle" />{item.country}
                                                    </span>
                                                    <span className="d-flex fw-medium mt-md-2">{item.salary}/mo</span>
                                                </div>
                                                <div className="mt-3 mt-md-0">
                                                    <Link to="#" className="btn btn-sm btn-icon btn-pills btn-soft-primary bookmark">
                                                        <FiBookmark className="icons" />
                                                    </Link>
                                                    <Link to={`/job-detail-one/${item.id}`} className="btn btn-sm btn-primary w-full ms-md-1">Apply Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* <div className="row">
                                <div className="col-12 mt-4 pt-2">
                                    <ul className="pagination justify-content-center mb-0">
                                        <li className="page-item">
                                            <Link className="page-link" to="#" aria-label="Previous">
                                                <span aria-hidden="true"><i className="mdi mdi-chevron-left fs-6"></i></span>
                                            </Link>
                                        </li>
                                        <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                        <li className="page-item">
                                            <Link className="page-link" to="#" aria-label="Next">
                                                <span aria-hidden="true"><i className="mdi mdi-chevron-right fs-6"></i></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                             {/* Pagination */}
                    <div className="row">
                        <div className="col-12 mt-4 pt-2">
                            <ul className="pagination justify-content-center mb-0">
                                {/* Previous Button */}
                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={handlePreviousPage} aria-label="Previous">
                                        <span aria-hidden="true"><i className="mdi mdi-chevron-left fs-6"></i></span>
                                    </button>
                                </li>

                                {/* Visible Page Numbers */}
                                {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((pageNum) => (
                                    <li key={pageNum} className={`page-item ${page === pageNum ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageClick(pageNum)}>{pageNum}</button>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={handleNextPage} aria-label="Next">
                                        <span aria-hidden="true"><i className="mdi mdi-chevron-right fs-6"></i></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboutTwo />
            <Footer />
            <ScrollTop />
        </>
    );
}