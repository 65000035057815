import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../assets/images/hero/bg.jpg'
import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
export default function JobPost(){
    return(
        <>
        <Helmet>
             <title>Create a Job Post | Reach Top Talent on 9 Jobs</title>
            <meta name="description" content="Post jobs on 9 Jobs and connect with thousands of qualified candidates. Set up your account quickly and start reaching the right talent with easy job management tools and insightful analytics." />
            <meta name="keywords" content="create job post, post a job, find candidates, job posting, 9 Jobs, talent recruitment, employer tools, job provider" />

            <meta property="og:title" content="Create a Job Post | Reach Top Talent on 9 Jobs" />
            <meta property="og:description" content="Ready to post a job on 9 Jobs? Download the app, create an account, and start connecting with top talent using powerful job management and analytics tools." />
            <meta property="og:image" content="https://9jobs.in/images/og-image-job-post.jpg" />
            <meta property="og:url" content="https://9jobs.in/job-post" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Create a Job Post | Reach Top Talent on 9 Jobs" />
            <meta name="twitter:description" content="Post jobs effortlessly on 9 Jobs. Gain access to thousands of potential candidates and advanced management tools for a seamless hiring experience." />
            <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-job-post.jpg" />



        </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Create a Job Post</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item"><Link to="/job-list-two">Job</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Job Post</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8">
                        <div className="card border-0 text-center">
                            <div className="p-4">
                                <h5 className="mb-3">Ready to Post a Job on 9Jobs?</h5>
                                <p className="text-muted">
                                    To start posting jobs on 9Jobs, download our app, create an account, and log in as an job provider(employer). With a simple account setup, you’ll gain access to thousands of potential candidates, easy job management tools, and powerful analytics. Start connecting with the right talent today!
                                </p>
                                <a href="https://play.google.com/store/apps/details?id=com.mobiletrendzy.mobiletracker" className="btn btn-primary mt-3">
                                    <i className="uil uil-download-alt"></i> Download the 9Jobs App
                                </a>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </section>

        <Footer top={true} />
        <ScrollTop/>
        </>
    )
}