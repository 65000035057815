import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../assets/images/hero/bg.jpg'
import logo1 from '../assets/images/company/lenovo-logo.png'

import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
export default function JobApply(){
    return(
        <>
        <Helmet>
        <title>Apply for Your Dream Job - 9 Jobs</title>
            <meta name="description" content="Discover and apply for top job opportunities across nine industries. Join millions of users on 9 Jobs to advance your career." />
            <meta name="keywords" content="apply job, job application, 9 Jobs, job opportunities, job search, career growth" />

            <meta property="og:title" content="Apply for Your Dream Job - 9 Jobs" />
            <meta property="og:description" content="Find and apply for jobs in various fields on 9 Jobs. Connect with top employers and secure your next position!" />
            <meta property="og:image" content="https://9jobs.in/images/og-image-job-apply.jpg" />
            <meta property="og:url" content="https://9jobs.in/job-apply" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Apply for Your Dream Job - 9 Jobs" />
            <meta name="twitter:description" content="Explore a variety of job openings and apply to move your career forward with 9 Jobs." />
            <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-job-apply.jpg" />

        </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <img src={logo1} className="avatar avatar-small rounded-pill p-2 bg-white" alt=""/>
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-3">Back-End Developer</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item"><Link to="/job-list-two">Job</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Job Apply</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="card border-0">
                                <div className="rounded shadow p-4 text-center">
                                    <h4 className="mb-4">Apply for Jobs Easily!</h4>
                                    <p className="mb-3">To apply for this position and discover additional job opportunities, we suggest downloading the 9Jobs Android app</p>
                                    <p className="mb-4">Get personalized job alerts, apply with a single tap, and track your applications all in one place!</p>
                                    <Link to="https://play.google.com/store/apps/details?id=com.mobiletrendzy.mobiletracker" className="btn btn-primary">Download the 9Jobs App</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer top={true}/>
        <ScrollTop/>
        </>
    )
}