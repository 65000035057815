import React from "react";
import { Link } from "react-router-dom";

// import { servicesData } from "../data/data";

export default function AboutTwo() {
    return (
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center mb-4 pb-2">
                <div className="col-12">
                    <div className="section-title text-center">
                        <h4 className="title mb-3">Why You'll Love 9Jobs</h4>
                        <p className="text-muted para-desc mx-auto mb-0">
                            Discover thousands of job opportunities across various industries. Receive personalized salary estimates and gain insights into company cultures with reviews from over 30,000 organizations worldwide. 9Jobs makes your career journey seamless.
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className="row">
                {servicesData.slice(0, 4).map((item, index) => {
                    let Icon = item.icon;
                    return (
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2" key={index}>
                            <div className="position-relative features text-center p-4 rounded shadow bg-white">
                                <div className="feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center">
                                    <Icon className="fea icon-ex-md" />
                                </div>

                                <div className="mt-4">
                                    <Link to="" className="title h5 text-dark">{item.title}</Link>
                                    <p className="text-muted mt-3 mb-0">{item.desc}</p>
                                    <div className="mt-3">
                                        <Link to="" className="btn btn-link primary text-dark">Read More <i className="mdi mdi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div> */}
        </div>
    );
}
