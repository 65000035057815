import React, { useState, useEffect } from "react";

import Select from 'react-select';
import {FiBriefcase, FiMapPin, FiSearch,FiClock, FiBookmark} from "../assets/icons/vander"

import { Link } from "react-router-dom";

import bg1 from '../assets/images/hero/bg.jpg';
import { locations,types } from "../utility/utility";
import Navbar from "../componants/navbar";
import AboutTwo from "../componants/aboutTwo";
import FormSelect from "../componants/formSelect";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";


export default function JobListOne() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("");
    const [location, setLocation] = useState("");
    const [jobType, setJobType] = useState([]);
    const [salaryRange, setSalaryRange] = useState("");
    const [page, setPage] = useState(1); // Pagination: Track the current page
    const [totalPages, setTotalPages] = useState(20); // Track the total number of pages
    const [keyword, setKeyword] = useState("");
    const [title, setTitle] = useState("");
    const pageLimit = 10; // Maximum pages to display at a time
    // Fetch jobs from API with filters and pagination
    const fetchJobs = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://www.9jobs.in/job-fetcher/jobs/recentJobs?page='+page+'&limit='+pageLimit, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                   
                    category,
                    location,
                    jobType: jobType.join(","), // Join selected job types as comma-separated values
                    salaryRange,
                    jobTitle:title
                }),
            });
            const data = await response.json();
            setJobs(data.data);
            setTotalPages(data.totalPages); // Set total pages from the response
        } catch (error) {
            console.error("Error fetching jobs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, [page, category, location, jobType, salaryRange,keyword]);

    // Pagination: Calculate start and end pages for the visible pagination
    const startPage = Math.max(1, page - Math.floor(pageLimit / 2));
    const endPage = Math.min(totalPages, startPage + pageLimit - 1);

   
    const handlePageClick = (pageNum) => {
        setPage(pageNum);
    };
    const handleTitleChange = (e) => setTitle(e.target.value);
    // Handle filter changes
    const handleCategoryChange = (e) => setCategory(e.target.value);
    const handleLocationChange = (e) => setLocation(e.target.value);
    const handleJobTypeChange = (e) => {
        const value = e.target.value;
        setJobType((prev) =>
            prev.includes(value) ? prev.filter((type) => type !== value) : [...prev, value]
        );
    };
    const handleSalaryRangeChange = (e) => setSalaryRange(e.target.value);

    // Handle pagination
    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleFilterSubmit = ({ keyword, selectedLocation, selectedType }) => {
        setTitle(keyword);
     
        setLocation(selectedLocation ? selectedLocation.value : "");
        setJobType(selectedType ? [selectedType.value] : []);
    }

    // const [keyword, setKeyword] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
  
    const handleSubmit = (e) => {
        e.preventDefault();
     
        let location = selectedLocation ? selectedLocation.value : ""; // Get location label
        console.log("location="+location)
        let type = selectedType ? selectedType.value : ""; // Get selectedType value
        console.log("type="+type)
        // alert("submite=="+location)
        // Pass the filter values back to the parent component
      //  onSubmit({ keyword, location, selectedType: type });
      setLocation(type)
    };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />

            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Job Vacancies</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 mt-4">
                            <div className="features-absolute">
                                <div className="d-md-flex justify-content-between align-items-center bg-white shadow rounded p-4">
                                <form className="card-body text-start" onSubmit={handleSubmit}>
                                <div className="registration-form text-dark text-start">
                                    <div className="row g-lg-0">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="mb-3 mb-sm-0">
                                                <label className="form-label d-none fs-6">Search :</label>
                                                <div className="filter-search-form position-relative filter-border">
                                                    <FiSearch className="fea icon-20 icons" />
                                                    <input
                                                        type="text"
                                                        className="form-control filter-input-box bg-light border-0"
                                                        placeholder="Search your keywords"
                                                        value={keyword}
                                                        onChange={(e) => setKeyword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="mb-3 mb-sm-0">
                                                <label className="form-label d-none fs-6">Location:</label>
                                                <div className="filter-search-form position-relative filter-border">
                                                    <FiMapPin className="fea icon-20 icons" />
                                                    <Select
                                                        options={locations}
                                                        value={selectedLocation}
                                                        onChange={setSelectedLocation}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="mb-3 mb-sm-0">
                                                <label className="form-label d-none fs-6">Type :</label>
                                                <div className="filter-search-form relative filter-border">
                                                    <FiBriefcase className="fea icon-20 icons" />
                                                    <Select
                                                        options={types}
                                                        value={selectedType}
                                                        onChange={setSelectedType}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 col-12">
                                            <input
                                                type="submit"
                                                className="btn btn-primary searchbtn w-100"
                                                value="Search"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-60">
                    {loading ? (
                        <div>Loading jobs...</div>
                    ) : (
                        <div className="row g-4">
                            {jobs.map((item, index) => (
                                <div className="col-12" key={index}>
                                    <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                        <div className="d-flex align-items-center w-310px">
                                            <img src={"https://www.9jobs.in/employer/"+item.image} className="avatar avatar-small rounded shadow p-3 bg-white" alt="" />

                                            <div className="ms-3">
                                                <Link to={`/job-detail-one/${item.id}`} className="h5 title text-dark">{item.title}</Link>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                            <span className="badge bg-soft-primary rounded-pill">{item.jobTime}</span>
                                            <span className="text-muted d-flex align-items-center fw-medium mt-md-2">
                                                <FiClock className="fea icon-sm me-1 align-middle" />
                                                {item.posted} days ago
                                            </span>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between d-md-block mt-2 mt-md-0 w-130px">
                                            <span className="text-muted d-flex align-items-center">
                                                <FiMapPin className="fea icon-sm me-1 align-middle" />
                                                {item.country}
                                            </span>
                                            <span className="d-flex fw-medium mt-md-2">$950 - $1100/mo</span>
                                        </div>

                                        <div className="mt-3 mt-md-0">
                                            <Link to="" className="btn btn-sm btn-icon btn-pills btn-soft-primary bookmark">
                                                <FiBookmark className="icons" />
                                            </Link>
                                            <Link to={`/job-detail-one/${item.id}`} className="btn btn-sm btn-primary w-full ms-md-1">
                                                Apply Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Pagination */}
                    <div className="row">
                        <div className="col-12 mt-4 pt-2">
                            <ul className="pagination justify-content-center mb-0">
                                {/* Previous Button */}
                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={handlePreviousPage} aria-label="Previous">
                                        <span aria-hidden="true"><i className="mdi mdi-chevron-left fs-6"></i></span>
                                    </button>
                                </li>

                                {/* Visible Page Numbers */}
                                {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((pageNum) => (
                                    <li key={pageNum} className={`page-item ${page === pageNum ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageClick(pageNum)}>{pageNum}</button>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={handleNextPage} aria-label="Next">
                                        <span aria-hidden="true"><i className="mdi mdi-chevron-right fs-6"></i></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <AboutTwo />
            </section>
            <Footer top={true} />
            <ScrollTop />
        </>
    );
}
