import React, { useState } from 'react';
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"
import Navbar from "../componants/navbar";
import { accordionData } from '../data/data';
import Footer from '../componants/footer';

import { FiArrowRight } from "../assets/icons/vander"
import ScrollTop from '../componants/scrollTop';
import { Helmet } from 'react-helmet';
export default function Terms() {
    let [activeIndex, setActiveIndex] = useState(0)
    return (
        <>
          <Helmet>
            <title>Terms of Service | 9 Jobs - User Agreement</title>
            <meta name="description" content="Read the Terms of Service for 9 Jobs to understand your rights and responsibilities. By using our app, you agree to comply with our terms and conditions." />
            <meta name="keywords" content="9 Jobs terms of service, terms and conditions, user agreement, 9jobs.in policies, app terms, usage rules" />

            <meta property="og:title" content="Terms of Service | 9 Jobs - User Agreement" />
            <meta property="og:description" content="By using the 9 Jobs app, you agree to our terms and conditions. Learn more about your rights, responsibilities, and our policies." />
            <meta property="og:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />
            <meta property="og:url" content="https://9jobs.in/terms" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Terms of Service | 9 Jobs - User Agreement" />
            <meta name="twitter:description" content="Access the 9 Jobs Terms of Service to understand your user rights, responsibilities, and our terms for app use." />
            <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-terms.jpg" />
            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />

            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Terms of Services</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">T&C</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="card shadow border-0 rounded">
                                <div className="card-body">
                                    <h5 className="card-title">Terms and Conditions for 9jobs.in</h5>
                                    <p className="text-muted">Effective Date: 1-1-2023</p>
                                    <p className="text-muted">Welcome to 9jobs.in. By using our Android app ("App"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please review them carefully.</p>

                                    <h5 className="card-title">1. Acceptance of Terms</h5>
                                    <p className="text-muted">By accessing or using the App, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>

                                    <h5 className="card-title">2. Changes to Terms</h5>
                                    <p className="text-muted">9jobs.in reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the App after such modifications will constitute your acknowledgment of the modified Terms and your agreement to abide by and be bound by the modified Terms.</p>

                                    <h5 className="card-title">3. Use of the App</h5>
                                    <p className="text-muted"><strong>Eligibility:</strong> You must be at least 18 years old to use the App. By using the App, you represent and warrant that you have the right, authority, and capacity to enter into these Terms and to abide by all of the terms and conditions set forth herein.</p>
                                    <p className="text-muted"><strong>User Conduct:</strong> You agree not to use the App for any unlawful purpose or in any way that could damage, disable, overburden, or impair the App. You also agree not to interfere with the security or proper functioning of the App or any information or data contained therein.</p>

                                    <h5 className="card-title">4. User Accounts</h5>
                                    <p className="text-muted"><strong>Account Creation:</strong> To access certain features of the App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                                    <p className="text-muted"><strong>Account Security:</strong> You are responsible for safeguarding your password and for all activities that occur under your account. You agree to notify 9jobs.in immediately of any unauthorized use of your account or any other breach of security.</p>

                                    <h5 className="card-title">5. Intellectual Property</h5>
                                    <p className="text-muted">The App and its original content, features, and functionality are and will remain the exclusive property of 9jobs.in and its licensors. The App is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of 9jobs.in.</p>

                                    <h5 className="card-title">6. Termination</h5>
                                    <p className="text-muted">We may terminate or suspend your account and bar access to the App immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

                                    <h5 className="card-title">7. Limitation of Liability</h5>
                                    <p className="text-muted">In no event shall 9jobs.in, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the App; (ii) any conduct or content of any third party on the App; (iii) any content obtained from the App; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                                    <h5 className="card-title">8. Governing Law</h5>
                                    <p className="text-muted">These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.</p>

                                    <h5 className="card-title">9. Contact Us</h5>
                                    <p className="text-muted">If you have any questions, feedback, or need assistance, please don’t hesitate to reach out to us at <a href="mailto:info@9jobs.in">info@9jobs.in</a>. We’re here to support you every step of the way.</p>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <ScrollTop />
        </>
    )
}