import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../componants/navbar";
import About from "../componants/aboutUs"
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
// import { servicesData, teamData } from "../data/data";
import {FiFacebook, FiInstagram, FiTwitter, FiHelpCircle} from "../assets/icons/vander"

export default function AboutUs() {
    return (
        <>
            <Helmet>
                <title>About Us | 9 Jobs - Your Global Career Platform</title>
                <meta name="description" content="At 9 Jobs, we connect job seekers with employers across nine sectors worldwide. Discover thousands of job listings, personalized salary insights, and company reviews to make informed career choices." />
                <meta name="keywords" content="about 9 Jobs, job seekers, employers, career opportunities, global job listings, salary insights, company reviews, job search platform" />

                <meta property="og:title" content="About Us | 9 Jobs - Connecting Job Seekers and Employers Globally" />
                <meta property="og:description" content="Learn how 9 Jobs helps simplify the job search and hiring process by providing access to global job listings, salary estimates, company insights, and more." />
                <meta property="og:image" content="https://9jobs.in/images/og-image-aboutus.jpg" />
                <meta property="og:url" content="https://9jobs.in/aboutus" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us | 9 Jobs - Your Global Career Platform" />
                <meta name="twitter:description" content="Discover how 9 Jobs connects job seekers and employers across nine sectors with thousands of job listings, salary insights, and company reviews." />
                <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-aboutus.jpg" />


            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">About Us</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <About containerClass="container" />

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Questions & Answers</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">
                                    Get answers to the most frequently asked questions about 9Jobs, our process, and how we help you find your dream job or the perfect candidate.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 pt-2">
                        <div className="col-md-6 col-12">
                            <div className="d-flex">
                                <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                                <div className="flex-1">
                                    <h5 className="mt-0">How does <span className="text-primary">9Jobs</span> work?</h5>
                                    <p className="answer text-muted mb-0">
                                        9Jobs aggregates job postings from various sources, helping you discover thousands of career opportunities in one place. It also provides salary estimates, company reviews, and other insights to empower your job search.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="d-flex">
                                <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                                <div className="flex-1">
                                    <h5 className="mt-0">How do I create an account on 9Jobs?</h5>
                                    <p className="answer text-muted mb-0">
                                        Creating an account is simple. Just click "Sign Up" and provide the required information. You’ll gain access to personalized job recommendations, application tracking, and salary insights.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 mt-4 pt-2">
                            <div className="d-flex">
                                <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                                <div className="flex-1">
                                    <h5 className="mt-0">What makes <span className="text-primary">9Jobs</span> different from other job portals?</h5>
                                    <p className="answer text-muted mb-0">
                                        9Jobs offers more than just job listings. With detailed salary estimates, in-depth company reviews, and career insights, we provide the tools you need to make informed career decisions.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 mt-4 pt-2">
                            <div className="d-flex">
                                <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                                <div className="flex-1">
                                    <h5 className="mt-0">Is <span className="text-primary">9Jobs</span> secure for storing my data?</h5>
                                    <p className="answer text-muted mb-0">
                                        Yes, 9Jobs prioritizes your privacy and the security of your personal information. We use advanced security measures to protect your data, ensuring a safe and reliable job search experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-md-5 pt-md-3 mt-4 pt-2 justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title mb-4">Have more questions? Get in touch!</h4>
                                <p className="text-muted para-desc mx-auto">
                                    Start working with <span className="text-primary fw-bold">9Jobs</span> and let us help you achieve your career goals or find the right candidates for your team.
                                </p>
                                <Link to="/contactus" className="btn btn-primary mt-3">
                                    <i className="uil uil-phone"></i> Contact us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}