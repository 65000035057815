import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../assets/images/hero/bg.jpg'

import Navbar from "../componants/navbar";
import AboutTwo from "../componants/aboutTwo";
import AboutUs from "../componants/aboutUs";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
import { jobOpenings } from "../data/data";

export default function Career(){
    return(
        <>
        <Helmet>
                <title>Careers at 9 Jobs | Job Openings for Software Developer & Marketing Specialist</title>
                <meta name="description" content="Explore career opportunities at 9 Jobs! We are hiring talented Software Developers and Marketing Specialists to join our remote team. Be part of the future of job search and recruitment." />
                <meta name="keywords" content="9 Jobs careers, job openings, software developer position, marketing specialist role, remote jobs, tech careers, 9jobs.in jobs" />

                <meta property="og:title" content="Join Our Team | 9 Jobs - Job Openings" />
                <meta property="og:description" content="9 Jobs is hiring! We're looking for passionate professionals in software development and marketing to join our team. Apply now to make an impact in the job search industry." />
                <meta property="og:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />
                <meta property="og:url" content="https://9jobs.in/career" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Careers at 9 Jobs | Join Our Team" />
                <meta name="twitter:description" content="9 Jobs has exciting remote openings! Apply for Software Developer and Marketing Specialist positions today and be part of our mission." />
                <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-careers.jpg" />

        </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Job Opening</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Career</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section pt-0">
    {/* <AboutTwo />
    <AboutUs containerClass="container mt-100 mt-60" /> */}

    <div className="container mt-100 mt-60">
        <div className="row justify-content-center mb-4 pb-2">
            <div className="col-12">
                <div className="section-title text-center">
                    <h4 className="title mb-3">Join Our Team at 9jobs.in!</h4>
                    <p className="text-muted para-desc mx-auto mb-0">
                        At 9jobs.in, we are always looking for talented, passionate individuals to join our team. If you are interested in making a difference in the job search and recruitment industry, we want to hear from you!
                    </p>
                </div>
            </div>
        </div>

        <div className="row justify-content-center">
            {[
                {
                    title: "Software Developer",
                    location: "Remote",
                    description: `We are looking for a skilled Software Developer to join our development team. You will be responsible for designing, coding, and maintaining our web and mobile applications.`,
                    responsibilities: [
                        "Develop and maintain web and mobile applications.",
                        "Collaborate with cross-functional teams to define and design new features.",
                        "Troubleshoot and fix bugs to ensure optimal performance.",
                        "Stay up-to-date with the latest industry trends and technologies."
                    ],
                    requirements: [
                        "Bachelor's degree in Computer Science or related field.",
                        "Proven experience as a Software Developer.",
                        "Familiarity with Agile development methodologies.",
                        "Strong problem-solving skills and attention to detail."
                    ],
                    contact: "info@9jobs.in"
                },
                {
                    title: "Marketing Specialist",
                    location: "Remote",
                    description: `We are seeking a creative Marketing Specialist to join our marketing team. You will be responsible for developing and implementing marketing strategies to promote our brand and services.`,
                    responsibilities: [
                        "Develop and execute marketing campaigns across various channels.",
                        "Create engaging content for social media, blogs, and newsletters.",
                        "Analyze and report on the performance of marketing campaigns.",
                        "Collaborate with the design team to create promotional materials."
                    ],
                    requirements: [
                        "Bachelor's degree in Marketing, Communications, or related field.",
                        "Proven experience as a Marketing Specialist or similar role.",
                        "Strong understanding of digital marketing strategies and tools.",
                        "Excellent written and verbal communication skills."
                    ],
                    contact: "info@9jobs.in"
                }
            ].map((job, index) => (
                <div className="col-lg-10 col-12 mt-4 pt-2" key={index}>
                    <div className="job-post shadow rounded p-4">
                        <h5 className="title text-dark">{job.title}</h5>
                        <p className="text-muted">Location: {job.location}</p>
                        <p className="text-muted">{job.description}</p>

                        <h6 className="text-muted">Responsibilities:</h6>
                        <ul className="text-muted">
                            {job.responsibilities.map((responsibility, idx) => (
                                <li key={idx}>{responsibility}</li>
                            ))}
                        </ul>

                        <h6 className="text-muted">Requirements:</h6>
                        <ul className="text-muted">
                            {job.requirements.map((requirement, idx) => (
                                <li key={idx}>{requirement}</li>
                            ))}
                        </ul>

                        <p className="text-muted">
                            If you are interested in this position, please send your resume and cover letter to <a href={`mailto:${job.contact}`}>{job.contact}</a>.
                        </p>
                    </div>
                </div>
            ))}
        </div>

        <div className="row justify-content-center mt-5">
            <div className="col-12">
                <div className="section-title text-center">
                    <h4 className="title mb-3">Why Work With Us?</h4>
                    <p className="text-muted para-desc mx-auto mb-0">
                        At 9jobs.in, we offer a dynamic and inclusive work environment where every team member is valued. We provide opportunities for growth and development, competitive salaries, and a comprehensive benefits package. Join us and be a part of a company that is transforming the job search and recruitment industry.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


        <Footer top={true}/>
        <ScrollTop/>
        </>
    )
}