import React from 'react';

const JobDescription = ({ jobData = {} }) => {
  // Handle the case where description might be missing
  const cleanDescription = jobData?.description
    ? jobData.description.replace(/\\r\\n/g, "<br>").replace(/\\"/g, '"')
    : "No description available.";

  return (
    <div className="job-description">
   
      {/* Render the HTML content inside the description safely */}
      <p
        className="text-muted"
        dangerouslySetInnerHTML={{ __html: cleanDescription }}
      />
    </div>
  );
};

export default JobDescription;
