// src/services/jobService.js

import axios from 'axios';

const BASE_URL = 'https://www.9jobs.in/job-fetcher/jobs';

export const getJobDetails = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/${id}`);
        return response.data; // Adjust according to your API response structure
    } catch (error) {
        throw new Error('Error fetching job data');
    }
};
