import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";

import { FiArrowRight } from "../assets/icons/vander"
import { Helmet } from 'react-helmet';
export default function Privacy() {
    return (
        <>
           <Helmet>
               <title>Privacy Policy | 9 Jobs - Your Privacy Matters</title>
                <meta name="description" content="Read the Privacy Policy of 9 Jobs to understand how we collect, use, and protect your personal data. Learn about your rights, data security, and how we ensure privacy on our platform." />
                <meta name="keywords" content="9 Jobs privacy policy, data protection, user information, personal data security, privacy policy 9jobs.in, user rights" />

                <meta property="og:title" content="Privacy Policy | 9 Jobs - Your Privacy Matters" />
                <meta property="og:description" content="Discover how 9 Jobs collects, uses, and protects your personal information on our platform. Stay informed about your privacy and data security." />
                <meta property="og:image" content="https://9jobs.in/images/og-image-privacy-policy.jpg" />
                <meta property="og:url" content="https://9jobs.in/privacy" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Privacy Policy | 9 Jobs - Your Privacy Matters" />
                <meta name="twitter:description" content="Learn about 9 Jobs' commitment to protecting your personal data and how we secure user information. Read our privacy policy for details." />
                <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-privacy-policy.jpg" />



            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Privacy Policy</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">PRIVACY</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="card shadow rounded border-0">
                                <div className="card-body">
                                    <h5 className="card-title">Privacy Policy for 9jobs.in</h5>
                                    <p className="text-muted">Effective Date: 1-1-2023</p>
                                    <p className="text-muted">At 9jobs.in, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our Android app ("App").</p>

                                    <h5 className="card-title">1. Information We Collect</h5>
                                    <p className="text-muted"><strong>Personal Information:</strong> When you register or use the App, we may collect personal information such as your name, email address, phone number, and resume details.</p>
                                    <p className="text-muted"><strong>Usage Data:</strong> We collect information about how you use the App, including your device information, IP address, browser type, and usage patterns.</p>
                                    <p className="text-muted"><strong>Location Data:</strong> If you enable location services, we may collect your location data to provide location-based services.</p>

                                    <h5 className="card-title">2. How We Use Your Information</h5>
                                    <ul className="list-unstyled text-muted mt-3">
                                        <li><FiArrowRight className="fea icon-sm me-2" />To Provide Services: We use your information to operate, maintain, and improve the App, and to facilitate job searches and job postings.</li>
                                        <li><FiArrowRight className="fea icon-sm me-2" />Communication: We may use your contact information to send you updates, newsletters, and promotional materials.</li>
                                        <li><FiArrowRight className="fea icon-sm me-2" />Personalization: We use your data to personalize your experience, including suggesting jobs that match your profile.</li>
                                        <li><FiArrowRight className="fea icon-sm me-2" />Security: We use your information to enhance the security and safety of our App.</li>
                                    </ul>

                                    <h5 className="card-title">3. Sharing Your Information</h5>
                                    <p className="text-muted"><strong>With Employers:</strong> We share your profile information with employers when you apply for jobs.</p>
                                    <p className="text-muted"><strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate and maintain the App.</p>
                                    <p className="text-muted"><strong>Legal Compliance:</strong> We may disclose your information if required by law or to protect our rights and property.</p>

                                    <h5 className="card-title">4. Data Security</h5>
                                    <p className="text-muted">We use reasonable measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no internet or email transmission is ever fully secure or error-free.</p>

                                    <h5 className="card-title">5. Your Choices</h5>
                                    <p className="text-muted"><strong>Account Information:</strong> You can update or delete your account information at any time through the App settings.</p>
                                    <p className="text-muted"><strong>Communications:</strong> You can opt-out of receiving promotional communications by following the unsubscribe instructions provided in the communication.</p>

                                    <h5 className="card-title">6. Children's Privacy</h5>
                                    <p className="text-muted">The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

                                    <h5 className="card-title">7. Changes to This Privacy Policy</h5>
                                    <p className="text-muted">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                                    <h5 className="card-title">8. Contact Us</h5>
                                    <p className="text-muted">If you have any questions, feedback, or need assistance, please don’t hesitate to reach out to us at <a href="mailto:info@9jobs.in">info@9jobs.in</a>. We’re here to support you every step of the way.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <ScrollTop />
        </>
    )
}