import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as Link2 } from 'react-scroll';

import bg1 from "../assets/images/hero/bg.jpg"
import { Helmet } from 'react-helmet';
import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";

export default function HelpcenterFaq() {
    const [activeIndex, setActiveIndex] = useState(null);

    // Updated FAQ content as per new content
    const faqData = [
        {
            category: "General Questions",
            items: [
                {
                    title: "How do I create an account?",
                    content: "To create an account, click on the 'Sign Up' button on the homepage, fill out the required information, and submit the form. You will receive a confirmation email to activate your account."
                },
                {
                    title: "How do I reset my password?",
                    content: "If you forget your password, click on the 'Forgot Password?' link on the login page. Follow the instructions sent to your email to reset your password."
                },
                {
                    title: "How can I contact customer support?",
                    content: "If you need further assistance, you can reach our customer support team via email at info@9jobs.in. We aim to respond within 24 hours."
                },
            ]
        },
        {
            category: "Job Application",
            items: [
                {
                    title: "How do I apply for a job?",
                    content: "Once you have created an account and logged in, browse the job listings, click on the desired job, and select the 'Apply' button. You can then upload your resume and submit your application."
                },
                {
                    title: "Can I edit my profile information?",
                    content: "Yes! After logging in, go to your profile settings where you can update your personal information, resume, and other details."
                }
            ]
        },
        {
            category: "Account Management",
            items: [
                {
                    title: "How do I delete my account?",
                    content: "If you wish to delete your account, please contact our support team via email. We will guide you through the process."
                },
                {
                    title: "Are there any fees to use the app?",
                    content: "No, the 9jobs application is free to use for both job seekers and employers."
                }
            ]
        }
    ];

    return (
        <>
            <Helmet>
               <title>Help and Support | 9 Jobs - Find Answers & Get Assistance</title>
                <meta name="description" content="Need help with 9 Jobs? Find answers to common questions about account setup, job applications, profile management, and more. Contact our support team for further assistance." />
                <meta name="keywords" content="9 Jobs help, 9 Jobs support, account setup, job application help, profile management, customer support, 9jobs.in" />

                <meta property="og:title" content="Help & Support | 9 Jobs" />
                <meta property="og:description" content="Visit 9 Jobs Help Center for answers to your questions. Learn how to set up your account, apply for jobs, manage your profile, or contact our support team for assistance." />
                <meta property="og:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />
                <meta property="og:url" content="https://9jobs.in/helpcenter-faqs" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Help & Support | 9 Jobs" />
                <meta name="twitter:description" content="Find help for 9 Jobs with account setup, job applications, and more. Contact us for additional support." />
                <meta name="twitter:image" content="https://9jobs.in/images/help-support-twitter.jpg" />


            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Help and Support</h5>
                                <p className="text-muted">Welcome to the Help page for 9jobs.in! Here you can find answers to common questions and learn how to get support when needed. Please note that the 9jobs application works exclusively on the Android app.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-7 col-12">
                            {faqData.map((faqSection, idx) => (
                                <div className="section-title mt-5" key={idx} id={faqSection.category.toLowerCase().replace(" ", "-")}>
                                    <h4>{faqSection.category}</h4>
                                    <div className="accordion mt-4 pt-2">
                                        {faqSection.items.map((item, index) => (
                                            <div className="accordion-item rounded mt-2" key={index}>
                                                <h2 className="accordion-header">
                                                    <button className={`${activeIndex === `${idx}-${index}` ? '' : 'collapsed'} accordion-button border-0 bg-light`} onClick={() => setActiveIndex(activeIndex === `${idx}-${index}` ? null : `${idx}-${index}`)}>
                                                        {item.title}
                                                    </button>
                                                </h2>
                                                <div className={`${activeIndex === `${idx}-${index}` ? 'show' : ''} accordion-collapse border-0 collapse`}>
                                                    <div className="accordion-body text-muted">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-12 text-center">
                            <p>If you have any other questions or need personalized support, please don't hesitate to contact us:</p>
                            <p>Email: <a href="mailto:info@9jobs.in">info@9jobs.in</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
