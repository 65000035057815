export let locations =[
    { "value": 1, "label": "Mumbai" },
    { "value": 2, "label": "Kolkata" },
    { "value": 7, "label": "Jaipur" },
    { "value": 8, "label": "Gandhinagar" },
    { "value": 11, "label": "Rajkot" },
    { "value": 12, "label": "Other" },
    { "value": 13, "label": "Bangalore" },
    { "value": 14, "label": "Vadodara" },
    { "value": 15, "label": "Delhi" },
    { "value": 16, "label": "Ahmedabad" },
    { "value": 17, "label": "Surat" },
    { "value": 19, "label": "Hyderabad" },
    { "value": 20, "label": "Novaluea" },
    { "value": 21, "label": "Gurgaon" },
    { "value": 22, "label": "Chandigarh" },
    { "value": 23, "label": "Lucknow" },
    { "value": 24, "label": "Varanasi" },
    { "value": 25, "label": "Amritsar" },
    { "value": 26, "label": "Bengaluru" },
    { "value": 27, "label": "Chennai" },
    { "value": 28, "label": "Coimbatore" },
    { "value": 29, "label": "Kochi" },
    { "value": 30, "label": "Mysuru" },
    { "value": 31, "label": "Pune" },
    { "value": 32, "label": "Goa" },
    { "value": 33, "label": "Guwahati" },
    { "value": 34, "label": "Bhubaneswar" },
    { "value": 35, "label": "Ranchi" },
    { "value": 36, "label": "Patna" },
    { "value": 37, "label": "Bhopal" },
    { "value": 38, "label": "Indore" },
    { "value": 39, "label": "Shillong" },
    { "value": 40, "label": "Imphal" },
    { "value": 41, "label": "Panaji" },
    { "value": 42, "label": "California" },
    { "value": 43, "label": "Texas" },
    { "value": 44, "label": "Florvaluea" },
    { "value": 45, "label": "Virginia" },
    { "value": 46, "label": "Alaska" },
    { "value": 47, "label": "valueaho" },
    { "value": 48, "label": "Hawaii" },
    { "value": 49, "label": "Ohio" },
    { "value": 50, "label": "Arizona" },
    { "value": 51, "label": "Georgia (U.S. state)" },
    { "value": 52, "label": "Massachusetts" },
    { "value": 53, "label": "Pennsylvania" },
    { "value": 54, "label": "Colorado" },
    { "value": 55, "label": "New Jersey" },
    { "value": 56, "label": "Michigan" },
    { "value": 57, "label": "North Carolina" },
    { "value": 58, "label": "Illinois" },
    { "value": 59, "label": "Maryland" },
    { "value": 60, "label": "Indiana" },
    { "value": 61, "label": "Washington" },
    { "value": 62, "label": "New York State" },
    { "value": 63, "label": "Minnesota" },
    { "value": 64, "label": "Utah" },
    { "value": 65, "label": "Alabama" },
    { "value": 66, "label": "Oregon" },
    { "value": 67, "label": "Tennessee" },
    { "value": 68, "label": "Missouri" },
    { "value": 69, "label": "Montana" },
    { "value": 70, "label": "Maine" },
    { "value": 71, "label": "Connecticut" },
    { "value": 72, "label": "New Mexico" },
    { "value": 73, "label": "Louisiana" },
    { "value": 75, "label": "Kentucky" },
    { "value": 76, "label": "Kansas" },
    { "value": 77, "label": "Wisconsin" },
    { "value": 78, "label": "Wisconsin" },
    { "value": 79, "label": "New Hampshire" },
    { "value": 80, "label": "Nevada" },
    { "value": 81, "label": "Oklahoma" },
    { "value": 82, "label": "Iowa" },
    { "value": 83, "label": "South Carolina" },
    { "value": 84, "label": "Wyoming" },
    { "value": 85, "label": "Arkansas" },
    { "value": 86, "label": "Mississippi" },
    { "value": 87, "label": "North Dakota" },
    { "value": 88, "label": "Nebraska" },
    { "value": 89, "label": "West Virginia" },
    { "value": 90, "label": "Delaware" },
    { "value": 91, "label": "Vermont" },
    { "value": 92, "label": "Rhode Island" },
    { "value": 93, "label": "South Dakota" },
    { "value": 94, "label": "New South Wales" },
    { "value": 95, "label": "Victoria" },
    { "value": 96, "label": "Queensland" },
    { "value": 97, "label": "Western Australia" },
    { "value": 98, "label": "South Australia" },
    { "value": 99, "label": "Tasmania" },
    { "value": 100, "label": "Australian Capital Territory" },
    { "value": 101, "label": "Northern Territory" },
    { "value": 102, "label": "Alberta" },
    { "value": 103, "label": "British Columbia" },
    { "value": 104, "label": "Manitoba" },
    { "value": 105, "label": "New Brunswick" },
    { "value": 106, "label": "Newfoundland and Labrador" },
    { "value": 107, "label": "Northwest Territories" },
    { "value": 108, "label": "Nova Scotia" },
    { "value": 109, "label": "Nunavut" },
    { "value": 110, "label": "Ontario" },
    { "value": 111, "label": "Prince Edward Island" },
    { "value": 112, "label": "Quebec" },
    { "value": 113, "label": "Saskatchewan" },
    { "value": 114, "label": "Yukon" },
    { "value": 115, "label": "Auvergne-Rhône-Alpes" },
    { "value": 116, "label": "Bourgogne-Franche-Comté" },
    { "value": 117, "label": "Brittany" },
    { "value": 118, "label": "Centre-Val de Loire" },
    { "value": 119, "label": "Corsica" },
    { "value": 121, "label": "Grand Est" },
    { "value": 122, "label": "Hauts-de-France" },
    { "value": 123, "label": "Paris Region" },
    { "value": 124, "label": "Normandie" },
    { "value": 125, "label": "Nouvelle-Aquitaine" },
    { "value": 126, "label": "Occitanie" },
    { "value": 127, "label": "Pays de la Loire" },
    { "value": 128, "label": "Provence Alpes Côte d’Azur" },
    { "value": 129, "label": "North Rhine-Westphalia" },
    { "value": 130, "label": "Bavaria" },
    { "value": 131, "label": "Saxony" },
    { "value": 132, "label": "Hesse" },
    { "value": 133, "label": "Baden-Württemberg" },
    { "value": 134, "label": "Thuringia" },
    { "value": 135, "label": "Lower Saxony" },
    { "value": 136, "label": "Saarland" },
    { "value": 137, "label": "Schleswig-Holstein" },
    { "value": 138, "label": "Rhineland-Palatinate" },
    { "value": 139, "label": "Mecklenburg-Vorpommern" },
    { "value": 140, "label": "Saxony-Anhalt" },
    { "value": 142, "label": "Brandenburg" },
    { "value": 143, "label": "Bremen" },
    { "value": 144, "label": "Hamburg" },
    { "value": 145, "label": "Berlin" },
    { "value": 146, "label": "Abruzzo" },
    { "value": 147, "label": "Basilicata" },
    { "value": 148, "label": "Calabria" },
    { "value": 149, "label": "Campania" },
    { "value": 150, "label": "Emilia-Romagna" },
    { "value": 151, "label": "Friuli Venezia Giulia" },
    { "value": 152, "label": "Lazio" },
    { "value": 153, "label": "Liguria" },
    { "value": 154, "label": "Lombardy" },
    { "value": 155, "label": "Marche" },
    { "value": 156, "label": "Molise" },
    { "value": 157, "label": "Piemonte" },
    { "value": 158, "label": "Puglia" },
    { "value": 159, "label": "Sardinia" },
    { "value": 160, "label": "Sicily" },
    { "value": 161, "label": "Sicily" },
    { "value": 162, "label": "Trentino-Alto Adige/Südtirol" },
    { "value": 163, "label": "Tuscany" },
    { "value": 164, "label": "Umbria" },
    { "value": 165, "label": "Valle d'Aosta" },
    { "value": 166, "label": "Veneto" },
    { "value": 167, "label": "Auckland" },
    { "value": 168, "label": "Bay of Plenty" },
    { "value": 169, "label": "Canterbury" },
    { "value": 170, "label": "Gisborne" },
    { "value": 171, "label": "Hawke’s Bay" },
    { "value": 172, "label": "Manawatu-Wanganui" },
    { "value": 173, "label": "Marlborough" },
    { "value": 174, "label": "Nelson" },
    { "value": 175, "label": "Northland" },
    { "value": 176, "label": "Otago" },
    { "value": 177, "label": "Southland" },
    { "value": 178, "label": "Taranaki" },
    { "value": 179, "label": "Tasman" },
    { "value": 180, "label": "Waikato" },
    { "value": 181, "label": "Wellington" },
    { "value": 182, "label": "West Coast" },
    { "value": 183, "label": "Singapore" },
    { "value": 184, "label": "Andalusia" },
    { "value": 185, "label": "Aragon" },
    { "value": 186, "label": "Asturias" },
    { "value": 187, "label": "Balearic Islands" },
    { "value": 188, "label": "Basque country" },
    { "value": 189, "label": "Canary Islands" },
    { "value": 190, "label": "Cantabria" },
    { "value": 191, "label": "Castile and León" },
    { "value": 192, "label": "Castile-La Mancha" },
    { "value": 193, "label": "Catalonia" },
    { "value": 194, "label": "Extremadura" },
    { "value": 195, "label": "Galicia" },
    { "value": 196, "label": "La Rioja" },
    { "value": 197, "label": "Madrvalue" },
    { "value": 198, "label": "Murcia" },
    { "value": 199, "label": "Navarre" },
    { "value": 200, "label": "Valencia" },
    { "value": 201, "label": "England" },
    { "value": 202, "label": "Scotland" },
    { "value": 203, "label": "Wales" },
    { "value": 204, "label": "Northern Ireland" },
    { "value": 205, "label": "Andhra Pradesh" },
    { "value": 206, "label": "Arunachal Pradesh" },
    { "value": 207, "label": "Assam" },
    { "value": 208, "label": "Bihar" },
    { "value": 209, "label": "Chhattisgarh" },
    { "value": 210, "label": "Gujarat" },
    { "value": 211, "label": "Haryana" },
    { "value": 212, "label": "Himachal Pradesh" },
    { "value": 213, "label": "Jharkhand" },
    { "value": 214, "label": "Karnataka" },
    { "value": 215, "label": "Kerala" },
    { "value": 216, "label": "Madhya Pradesh" },
    { "value": 217, "label": "Maharashtra" },
    { "value": 218, "label": "Manipur" },
    { "value": 219, "label": "Meghalaya" },
    { "value": 220, "label": "Mizoram" },
    { "value": 221, "label": "Nagaland" },
    { "value": 222, "label": "Odisha" },
    { "value": 223, "label": "Punjab" },
    { "value": 224, "label": "Rajasthan" },
    { "value": 225, "label": "Sikkim" },
    { "value": 226, "label": "Tamil Nadu" },
    { "value": 227, "label": "Telangana" },
    { "value": 228, "label": "Tripura" },
    { "value": 229, "label": "Uttar Pradesh" },
    { "value": 230, "label": "Uttarakhand" },
    { "value": 231, "label": "West Bengal" },
    { "value": 232, "label": "Andaman and Nicobar Islands" },
    { "value": 233, "label": "Chandigarh" },
    { "value": 234, "label": "Dadra and Nagar Haveli and Daman and Diu" },
    { "value": 235, "label": "Lakshadweep" },
    { "value": 236, "label": "Puducherry" },
    { "value": 237, "label": "Jammu and Kashmir" },
    { "value": 238, "label": "Ladakh" },
    { "value": 239, "label": "Gurugram" },
    { "value": 240, "label": "Kurukshetra" }
]
export const types = [
    { value: "Freelance", label: "Freelance" },
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Contract", label: "Contract" },
    { value: "Internship", label: "Internship" },
];