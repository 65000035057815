import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios'; // Import axios
import logo1 from "../assets/images/company/lenovo-logo.png"
import bg1 from '../assets/images/hero/bg.jpg'
import { getJobDetails } from "../services/jobService";
import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";

import {FiLayout, FiMapPin,FiUserCheck, FiClock, FiMonitor, FiBriefcase, FiBook, FiDollarSign, FiArrowRight} from "../assets/icons/vander"
import JobDescription from "./JobDescription";


export default function JobDetailOne(){
    let params = useParams();
    let id = params.id;
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobData = async () => {
            try {
                const data = await getJobDetails(id); // Use the service call
                console.log("image=="+data.image)
                setJobData(data);
            } catch (err) {
                console.log("err=", err);
                setError("Error fetching job data");
            } finally {
                setLoading(false);
            }
        };

        fetchJobData();
    }, [id]);
    return(
        <>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            {/* <img src={data?.image ? data.image : logo1} className="avatar avatar-small rounded-pill p-2 bg-white" alt=""/>
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-3">{data?.title ? data.title : 'Back-End Developer'}</h5> */}
                               <img
                                    src={jobData?.image ? `https://www.9jobs.in/employer/${jobData.image}` : logo1}
                                    className="avatar avatar-small rounded-pill p-2 bg-white"
                                    alt="Company Logo"
                                />
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-3">
                                    {jobData?.title ? jobData.title : ""}
                                </h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item"><Link to="/job-list-two">Jobs</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Job Detail</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        
        <section className="section">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="card bg-white rounded shadow sticky-bar">
                                <div className="p-4">
                                    <h5 className="mb-0">Job Information</h5>
                                </div>

                                <div className="card-body p-4 border-top">
                                    <div className="d-flex widget align-items-center">
                                        <FiLayout className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Company Name:</h6>
                                            <small className="text-primary mb-0">{jobData?.company_name}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiUserCheck className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Job Type:</h6>
                                            <small className="text-primary mb-0">{jobData?.job_type}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiMapPin className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Location:</h6>
                                            <small className="text-primary mb-0">{jobData?.address}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiBriefcase className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Designation:</h6>
                                            <small className="text-primary mb-0">{jobData?.designation}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiBook className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Qualifications:</h6>
                                            <small className="text-primary mb-0">{jobData?.qualification}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiDollarSign className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Salary:</h6>
                                            <small className="text-primary mb-0">₹{parseFloat(jobData?.salary).toLocaleString()}</small>
                                        </div>
                                    </div>

                                    <div className="d-flex widget align-items-center mt-3">
                                        <FiClock className="fea icon-ex-md me-3" />
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Date Posted:</h6>
                                            <small className="text-primary mb-0">
                                                {new Date(jobData?.date * 1000).toLocaleDateString()}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-6 col-12">
                            <h5>Job Description:</h5>
                            {/* <p className="text-muted">{jobData?.description}</p> */}
                            <JobDescription jobData={jobData} />
                            <h5>Skills Required:</h5>
                            <p className="text-muted">{jobData?.skills}</p>

                            {/* Add more job details or sections as needed */}
                        </div>
                    </div>
                </div>
        </section>
        <Footer top={true}/>
        <ScrollTop/>
        </>
    )
}