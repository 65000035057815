import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../componants/navbar";
import Footer from "../componants/footer"
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
export default function HelpcenterSupport(){
    return(
        <>
         <Helmet>
         <title>Cookies Policy | 9 Jobs</title>
            <meta name="description" content="Learn about the use of cookies on 9jobs.in. Understand how cookies enhance your experience and your options for managing them." />
            <meta name="keywords" content="cookies policy, 9 Jobs cookies, website cookies, cookie usage, privacy policy, 9jobs.in" />

            <meta property="og:title" content="Cookies Policy | 9 Jobs" />
            <meta property="og:description" content="Explore our Cookies Policy to understand how we use cookies to enhance your experience on 9jobs.in." />
            <meta property="og:image" content="https://www.9jobs.in/employer/upload/logo/appLog.png" />
            <meta property="og:url" content="https://9jobs.in/cookies-policy" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Cookies Policy | 9 Jobs" />
            <meta name="twitter:description" content="Read our Cookies Policy to learn how cookies are used on 9jobs.in." />
            <meta name="twitter:image" content="https://9jobs.in/images/cookies-policy-twitter.jpg" />


            </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Cookies</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item"><Link to="/helpcenter-support">Cookies</Link></li>

                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section bg-light">
                <div className="container">
                    <h4 className="text-center">Cookies Policy</h4>
                    <p>Effective Date: 1-1-2023</p>
                    <p>
                        9jobs.in ("we", "our", "us") uses cookies on the 9jobs.in website (the "Service"). By using the Service, you consent to the use of cookies.
                    </p>
                    <h5>What are Cookies?</h5>
                    <p>
                        Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.
                    </p>
                    <h5>How We Use Cookies</h5>
                    <p>
                        When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes:
                    </p>
                    <ul>
                        <li>To enable certain functions of the Service</li>
                        <li>To provide analytics</li>
                        <li>To store your preferences</li>
                        <li>To enable advertisements delivery, including behavioral advertising</li>
                    </ul>
                    <h5>Your Choices Regarding Cookies</h5>
                    <p>
                        If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
                    </p>
                    <h5>Changes to This Cookies Policy</h5>
                    <p>
                        We may update our Cookies Policy from time to time. We will notify you of any changes by posting the new Cookies Policy on this page.
                    </p>
                    <h5>Contact Us</h5>
                    <p>
                        If you have any questions about this Cookies Policy, please contact us:<br />
                        Email: <a href="mailto:info@9jobs.in">info@9jobs.in</a>
                    </p>
                </div>
            </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}