import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Link,useNavigate } from "react-router-dom";
import heroImg from "../assets/images/hero/bg.jpg"
import Navbar from "../componants/navbar";
import FormSelect from "../componants/formSelect";
import ServicesTwo from "../componants/sercicesTwo";
import AboutUs from "../componants/aboutUs";
import Companies from "../componants/companies";
import Blog from "../componants/blog";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { locations, types } from "../utility/utility";
import { Helmet } from 'react-helmet';
import { FiClock, FiMapPin, FiBookmark, FiSearch, FiBriefcase } from "../assets/icons/vander"
import moment from 'moment';
export default function IndexTwo() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jobType, setJobType] = useState([]);
    const [salaryRange, setSalaryRange] = useState("");
    const [page, setPage] = useState(1); // Pagination: Track the current page
    const [totalPages, setTotalPages] = useState(20); // Track the total number of pages
    const [keyword, setKeyword] = useState("");
    const [title, setTitle] = useState("");
    const pageLimit = 10; // Maximum pages to display at a time
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [category, setCategory] = useState("");
    const [location, setLocation] = useState("");
    const navigate = useNavigate(); // useNavigate hook
    const handleSubmit = (e) => {
        e.preventDefault();

        let location = selectedLocation ? selectedLocation.value : ""; // Get location label
        console.log("location=" + location);
        let type = selectedType ? selectedType.value : ""; // Get selectedType value
        console.log("type=" + type);

        // Pass the filter values back to the parent component
        setLocation(type);

        // Navigate to job-list-Two screen after submitting the form
        navigate("/job-list-Two", {
            state: {
                keyword,
                location,
                jobType: type
            }
        });
    };
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch('https://www.9jobs.in/job-fetcher/jobs/recentJobs', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                   body: JSON.stringify({
                   
                    category,
                    location,
                    jobType: jobType.join(","), // Join selected job types as comma-separated values
                    salaryRange,
                    jobTitle:title
                }),
                });
                const data = await response.json();
                setJobs(data.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching jobs:", error);
                setLoading(false);
            }
        };

        fetchJobs();
    }, [page, category, location, jobType, salaryRange,keyword]);
    return (
        <>
           <Helmet>
                <title>Find & Hire Experts | 9 Jobs - Your Global Career Platform</title>
                    <meta name="description" content="Discover jobs, career opportunities, and top talent in 9 key sectors. Join 9 Jobs to connect with companies worldwide and find your next career move or hire exceptional experts." />
                    <meta name="keywords" content="find jobs, hire experts, career opportunities, employment, job seekers, global career platform, job listings, talent recruitment, 9 Jobs" />

                    <meta property="og:title" content="9 Jobs - Your Global Career Platform" />
                    <meta property="og:description" content="9 Jobs connects job seekers with careers and employers with top talent. Discover thousands of jobs across nine industries, personalized salary insights, and company reviews." />
                    <meta property="og:image" content="https://9jobs.in/images/og-image-home.jpg" />
                    <meta property="og:url" content="https://9jobs.in/" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Find & Hire Experts | 9 Jobs - Your Global Career Platform" />
                    <meta name="twitter:description" content="Explore thousands of jobs and hire experts in various fields with 9 Jobs. Your trusted platform for connecting job seekers and employers worldwide." />
                    <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-home.jpg" />

            </Helmet>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-260 d-table w-100" style={{ backgroundImage: `url(${heroImg})` }}>
                <div className="bg-overlay bg-primary-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="title-heading text-center">
                                <h1 className="heading text-white fw-bold">Find & Hire Experts <br /> for any Job</h1>
                                <p className="para-desc text-white-50 mx-auto mb-0">Find Jobs, Employment & Career Opportunities. Some of the companies we've helped recruit excellent applicants over the years.</p>

                                <div className="d-md-flex justify-content-between align-items-center bg-white shadow rounded p-2 mt-4">
                                    <form className="card-body text-start" onSubmit={handleSubmit}>
                                        <div className="registration-form text-dark text-start">
                                            <div className="row g-lg-0">
                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="mb-3 mb-sm-0">
                                                        <label className="form-label d-none fs-6">Search :</label>
                                                        <div className="filter-search-form position-relative filter-border">
                                                            <FiSearch className="fea icon-20 icons" style={{marginRight:30}}  />
                                                            <input
                                                                type="text"
                                                                className="form-control filter-input-box bg-light border-0"
                                                                placeholder="Search your keywords"
                                                                value={keyword}
                                                                onChange={(e) => setKeyword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="mb-3 mb-sm-0">
                                                        <label className="form-label d-none fs-6">Location:</label>
                                                        <div className="filter-search-form position-relative filter-border">
                                                            <FiMapPin className="fea icon-20 icons"  />
                                                            <div style={{marginLeft:30}}>
                                                            <Select
                                                                options={locations}
                                                                value={selectedLocation}
                                                                onChange={setSelectedLocation}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="mb-3 mb-sm-0">
                                                        <label className="form-label d-none fs-6">Type :</label>
                                                        <div className="filter-search-form relative filter-border">
                                                            <FiBriefcase className="fea icon-20 icons" />
                                                            <div style={{marginLeft:30}}>
                                                            <Select
                                                                options={types}
                                                                value={selectedType}
                                                                onChange={setSelectedType}
                                                            />
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <input
                                                        type="submit"
                                                        className="btn btn-primary searchbtn w-100"
                                                        value="Search"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="mt-2">
                                    <span className="text-white-50"><span className="text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center mb-4 pb-2">
                        <div className="col-12">
                           <div className="section-title text-center">
                                <h4 className="title mb-3">9jobs – Your Global Career Platform</h4>
                                <p className="text-muted para-desc mx-auto mb-0">At 9jobs, we are committed to connecting job seekers with their dream careers and employers with top talent. With over 250,000 job seekers from around the world, 9jobs offers an extensive network across 9 key sectors, providing thousands of job listings updated daily.</p>
                            </div>
                        </div>
                    </div>

                    <ServicesTwo />
                </div>
                <AboutUs containerClass="container mt-100 mt-60" />

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center mb-4 pb-2">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title mb-3">Top Job Opportunities</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Explore thousands of open positions in 9 key sectors with 9jobs. Get personalized salary estimates and insights into your career. Read reviews on top companies worldwide, all in one place. Let 9jobs help you land your dream job today!</p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 mt-0">


                        {loading ? (
                            <p>Loading jobs...</p>
                        ) : (
                            <div className="row g-4 mt-0">
                                {jobs.map((item) => (
                                    <div className="col-12" key={item.id}>
                                        <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                            <div className="d-flex align-items-center w-310px">
                                                <img src={"https://www.9jobs.in/employer/" + item.image} className="avatar avatar-small rounded shadow p-3 bg-white" alt="" />

                                                <div className="ms-3">
                                                    <Link to={`/job-detail-one/${item.id}`} className="h5 title text-dark">{item.title}</Link>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                                <span className="badge bg-soft-primary rounded-pill">{item.jobTime}</span>
                                                <span className="text-muted d-flex align-items-center fw-medium mt-md-2">
                                                <FiClock className="fea icon-sm me-1 align-middle" />
                                                
                                                { moment(item.date * 1000).fromNow() }
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between d-md-block mt-2 mt-md-0 w-130px">
                                                <span className="text-muted d-flex align-items-center">
                                                    <FiMapPin className="fea icon-sm me-1 align-middle" /> {item.country}
                                                </span>
                                                <span className="d-flex fw-medium mt-md-2">{item.salary}/mo</span>
                                            </div>

                                            <div className="mt-3 mt-md-0">
                                                <Link to="" className="btn btn-sm btn-icon btn-pills btn-soft-primary bookmark">
                                                    <FiBookmark className="icons" />
                                                </Link>
                                                <Link to={`/job-detail-one/${item.id}`} className="btn btn-sm btn-primary w-full ms-md-1">Apply Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}


                        <div className="col-12">
                            <div className="text-center">
                                <Link to="/job-list-two" className="btn btn-link primary text-muted">See More Jobs <i className="mdi mdi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <Companies />
                </div>

                {/* <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-3">Latest Blog or News</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                        </div>
                    </div>
                </div>

               <Blog/>
            </div> */}
            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}