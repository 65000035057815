import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"
import contact from "../assets/images/svg/contact.svg"

import { contactData } from "../data/data";

import Navbar from "../componants/navbar";
import Footer from "../componants/footer";
import ScrollTop from "../componants/scrollTop";
import { Helmet } from 'react-helmet';
export default function ContactUs(){
    return(
        <>
         <Helmet>
            <title>Contact Us | Get Support & Connect with 9 Jobs</title>
            <meta name="description" content="Connect with the 9 Jobs team for support, assistance, and inquiries. Download our app for a seamless way to reach us and explore job opportunities. Email us at info@9jobs.in." />
            <meta name="keywords" content="contact 9 Jobs, support, job assistance, career help, customer service, connect with 9 Jobs, Hyderabad" />

            <meta property="og:title" content="Contact Us | Get Support & Connect with 9 Jobs" />
            <meta property="og:description" content="Need assistance or have questions? Contact 9 Jobs for support and explore career opportunities. Email us at info@9jobs.in or visit us in Hyderabad." />
            <meta property="og:image" content="https://9jobs.in/images/og-image-contact-us.jpg" />
            <meta property="og:url" content="https://9jobs.in/contactus" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Contact Us | Get Support & Connect with 9 Jobs" />
            <meta name="twitter:description" content="Stay connected with 9 Jobs. For support or inquiries, contact us via email at info@9jobs.in or download our app for easy access to assistance." />
            <meta name="twitter:image" content="https://9jobs.in/images/twitter-image-contact-us.jpg" />
         </Helmet>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <p className="text-white-50 para-desc mx-auto mb-0">Get in touch !</p>
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Contact us</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">9Jobs</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section pb-0">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={contact} className="img-fluid" alt="Get in Touch" />
                    </div>
                    <div className="col-md-6">
                        <div className="p-4 rounded shadow ms-lg-5 text-center">
                            <h4>Connect with Us!</h4>
                            <p className="text-muted mt-3">
                                To stay connected and receive assistance, download the 9Jobs app. Our app offers a seamless way to contact us, find support, and explore career opportunities. For any queries, feel free to email us.
                            </p>
                            {/* <a href="https://9jobs-app-download-link" className="btn btn-primary mt-3">
                                <i className="uil uil-download-alt"></i> Download the 9Jobs App
                            </a> */}
                            {/* <p className="text-muted mt-3">Or email us at:</p> */}
                            <p className="fw-bold">info@9jobs.in</p>
                            <p className="text-muted">Location: Hyderabad</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2201280296616!2d78.38266721469334!3d17.447402488043934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93f5b8d4d5ff%3A0xe2bbd0ff473e0381!2sHITEC%20City!5e0!3m2!1sen!2sin!4v1697063319395!5m2!1sen!2sin" 
                                    title="9Jobs Location - Hyderabad Hitech City" 
                                    style={{border:'0'}} 
                                    allowFullScreen
                                    loading="lazy">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

</section>

        <Footer/>
        <ScrollTop/>
        </>
    )
}